.inquiry{
  margin-top: 15vh;
	margin-bottom: 10vh;
	min-width: 200px;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 255, 0, 0.14);
  padding: 50px;
  border-radius: 20px;
}

label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: 'Helvetica Neue', sans-serif;
}

button {
  background-color: rgba(119, 221, 119, 1);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgba(119, 221, 119, 0.5);
}
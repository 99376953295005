.chatInfo {
	position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 90vw;
	z-index: 2;
	padding-top: 65px;
}

@font-face {
  font-family: 'Gowun Dodum', sans-serif;
  font-style: normal;
  font-display: optional;  /* font로 인한 rerendering 방지 */
  src: local('Gowun Dodum'),
       url('https://fonts.gstatic.com/s/gowundodum/v7/3Jn5SD_00GqwlBnWc1TUJF0AFPJJ3oAcQhA685dKXogXvvOvaew.109.woff2') format('woff2');
}